// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-js": () => import("./../../../src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-templates-about-template-js": () => import("./../../../src/templates/AboutTemplate.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-codebook-template-js": () => import("./../../../src/templates/CodebookTemplate.js" /* webpackChunkName: "component---src-templates-codebook-template-js" */),
  "component---src-templates-compare-template-js": () => import("./../../../src/templates/CompareTemplate.js" /* webpackChunkName: "component---src-templates-compare-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/ContactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-country-template-js": () => import("./../../../src/templates/CountryTemplate.js" /* webpackChunkName: "component---src-templates-country-template-js" */),
  "component---src-templates-data-template-js": () => import("./../../../src/templates/DataTemplate.js" /* webpackChunkName: "component---src-templates-data-template-js" */),
  "component---src-templates-global-compare-template-js": () => import("./../../../src/templates/GlobalCompareTemplate.js" /* webpackChunkName: "component---src-templates-global-compare-template-js" */),
  "component---src-templates-how-to-template-js": () => import("./../../../src/templates/HowToTemplate.js" /* webpackChunkName: "component---src-templates-how-to-template-js" */),
  "component---src-templates-methods-template-js": () => import("./../../../src/templates/MethodsTemplate.js" /* webpackChunkName: "component---src-templates-methods-template-js" */),
  "component---src-templates-policy-template-js": () => import("./../../../src/templates/PolicyTemplate.js" /* webpackChunkName: "component---src-templates-policy-template-js" */),
  "component---src-templates-sources-template-js": () => import("./../../../src/templates/SourcesTemplate.js" /* webpackChunkName: "component---src-templates-sources-template-js" */)
}

